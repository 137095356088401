import React from 'react'
import Banner from '../../components/Banner/Banner'
import Benefits from '../../components/Benefits/Benefits'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/download.svg'
import './flatTaxi.css'
import { motion } from 'framer-motion';

const FlatTaxi = () => {
  const points = [
    {
      text: "White cabs Drivers & Passengers wear mask."
    },
    {
      text: "All taxis are sanitized."
    },
    {
      text: "No extra charges collect from passengers."
    },
    {
      text: "Sanitzed taxi."
    },
    {
      text: "Cheap & flat rate taxi service."
    },
    {
      text: "Near to your home."
    },
    {
      text: "Available taxi are SUV, Sedan, and Tempo."
    },
    {
      text: "Make luggage services available for free."
    },
  ]

  const demandService= [
    {
      title: "With White cabs, you can travel for 24 hours and 7 days",
      desc: "For shopping, business meetings, hotel taxis, or airport taxis, you can order white cabs at any time and from anywhere. White Cabs provides every service at a flat rate in Canada for any occasion. Passengers can book taxis for 24 & 7 hours. Now, you can hire a flat rate taxi every day, any time, any were in Canada. The pick-up and drop-off schedules for 24 Hour Taxi Services in spruce grove are quite rigorous, so customers do not have to waste waiting time. You can enjoy a taxi ride anytime or during transportation emergencies."
    },
    {
      title: "Flat rate taxi service for Airport :",
      desc: "To book a flat rate taxi service to the airport, call 780 203 8000 or book a cab from your home. Other variables should be considered when selecting an airport transfer. Passengers can book a taxi according to their needs, and unless additional services are requested, there are no extra fees. This allows for budgeting the transit cost in advance, which is not possible with a metered cab."
    },
  ]

  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} className='flat container'>
       <Banner comp="home" title="Flat Rate Taxi Service" description="White cabs are now available in Canada for hire anytime and anywhere. Passengers can hire flat rate taxis for various purposes including shopping, office use, personal use, one-day picking, hotel, airport, hospital, restaurant, and tourism. The company offers a variety of taxis such as Sedan, SUV, and Tempo at a flat rate for different types of passengers like single, two or more, and groups. Payments can be made through personal or co-operating accounts. Additionally, White cab also provides on-demand delivery services at a nominal fare.
" bannerImg={bannerImg} />
      <Benefits title="Why should travelers select White Cabs?" points={points}  />
      <Services title="Drivers of White Cabs" data={demandService} />
    </motion.div>
  )
}

export default FlatTaxi