import React from 'react'
import './bigCard.css';

const BigCard = ({title,desc}) => {
  return (
    <div className='bigCard'>
        <h3>{title}</h3>
        <p>{desc}</p>
    </div>
  )
}

export default BigCard