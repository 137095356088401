import { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Loader from './components/Loader/Loader';
import About from './pages/About/About';
import Booking from './pages/Booking/Booking';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home/Home';
import HotelTaxi from './pages/HotelTaxi/HotelTaxi.jsx'
import WhiteLimousine from './pages/WhiteLimousine/WhiteLimousine';
import OnDemand from './pages/OnDemand/OnDemand';
import ShoppingTaxi from './pages/ShoppingTaxi/ShoppingTaxi'
import WheelchairTaxi from './pages/WheelchairTaxi/WheelchairTaxi'
import AirportTaxi from './pages/AirportTaxi/AirportTaxi'
import BusinessTaxi from './pages/BusinessTaxi/BusinessTaxi'
import TaxiService from './pages/TaxiService/TaxiService'
import FlatTaxi from './pages/FlatTaxi/FlatTaxi'
import SpruceTaxi from './pages/SpruceTaxi/SpruceTaxi'
import StonyTaxi from './pages/StonyTaxi/StonyTaxi'
import EdmontonTaxi from './pages/EdmontonTaxi/EdmontonTaxi'
import ParklandTaxi from './pages/ParklandTaxi/ParklandTaxi'
import { AnimatePresence } from 'framer-motion';
import Blog from './pages/Blog/Blog';
import BlogDetail from './pages/BlogDetail/BlogDetail';

function App() {

  const location = useLocation();

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
  }, []);

  window.addEventListener("contextmenu", (e) => e.preventDefault());

  document.onkeydown = (e) => {
    if (e.key === 123) {
        e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.key === 'I') {
        e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.key === 'C') {
        e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.key === 'J') {
        e.preventDefault();
    }
    if (e.ctrlKey && e.key === 'U') {
        e.preventDefault();
    }
};

  if(loading) return <Loader />
  return(
    <div className="App">
      <Header />
      <AnimatePresence >
      <Routes location={location} key={location.pathname}>
        <Route path='/' exact element={<Home />} />
        <Route path='/about' exact element={<About />} />
        <Route path='/booking-online-taxi' exact element={<Booking />} />
        <Route path='/contact-us' exact element={<Contact />} />
        <Route path='/blogs' exact element={<Blog />} />
        <Route path='/white-limousine-service' exact element={<WhiteLimousine />} />
        <Route path='/hotel-taxi' exact element={<HotelTaxi />} />
        <Route path='/on-demand-delivery' exact element={<OnDemand />} />
        <Route path='/shopping-taxi-service' exact element={<ShoppingTaxi />} />
        <Route path='/wheelchair-van' exact element={<WheelchairTaxi />} />
        <Route path='/airport-taxi-edmonton' exact element={<AirportTaxi />} />
        <Route path='/airport-taxi' exact element={<AirportTaxi />} />
        <Route path='/business-taxi' exact element={<BusinessTaxi />} />
        <Route path='/24-7-taxi' exact element={<TaxiService />} />
        <Route path='/flat-taxi' exact element={<FlatTaxi />} />
        <Route path='/cab-spruce-taxi' exact element={<SpruceTaxi />} />
        <Route path='/stony-taxi' exact element={<StonyTaxi />} />
        <Route path='/edmonton-taxi-service' exact element={<EdmontonTaxi />} />
        <Route path='/parkland-taxi-services' exact element={<ParklandTaxi />} />
        <Route path='/blogs/the-benefits-of-using-a-cab-service-for-your-daily-commute' exact element={<BlogDetail />} />
        <Route path='/blogs/tips-for-finding-the-best-cab-service-in-your-area' exact element={<BlogDetail />} />
        <Route path='/blogs/how-technology-is-changing-the-cab-service-industry' exact element={<BlogDetail />} />
        <Route path='/blogs/the-importance-of-safety-in-cab-services' exact element={<BlogDetail />} />
      </Routes>
      </AnimatePresence>
      
      <Footer />
    </div>
  );
}

export default App;
