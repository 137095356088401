import React from "react";
import { Link } from "react-router-dom";
import andriodDownload from '../../images/andriodDownload.png';
import appleDownload from '../../images/appleDownload.png';

import andriodDownloadBtn from '../../images/google-btn.png';
import appleDownloadBtn from '../../images/apple-btn.jpg';

import "./banner.css";

const Banner = ({ comp, title, description, bannerImg ,comp2}) => {
  return (
    <div className="banner container">
      <div className="left_container">
        <h2>{title}</h2>
        <p>{description}</p>
        {comp === "home" && <Link to="https://whitecabs.webbooker.icabbi.com/"><button>BOOK NOW</button></Link>}
        {comp2 === "about" && <div className="download-btns"><Link className="left-btn" to="https://play.google.com/store/apps/details?id=com.whitecabs.passengerapp"> <button> <img src={andriodDownloadBtn} alt="andriod" /> </button></Link> <Link className="left-btn" to="https://apps.apple.com/gb/app/white-cabs/id1637899683"><button> <img src={appleDownloadBtn} alt="apple" /></button></Link></div> }
      </div>
      <div className="right_container">
        {comp === "home" ? <img src={bannerImg} alt="bannerImg" /> : 
        <div className="about__card">
          <p>White cabs started 7 years ago as cooperative taxi. White Cabs is a 24/7 cooperative taxi company that prioritizes customer satisfaction. Their fleet is safe, reliable, and well-maintained, and drivers undergo rigorous training to ensure a pleasant and trouble-free trip. They offer special vehicles for disabled individuals, an affordable parcel delivery service, and comply with strict standards of upkeep and cleanliness.</p>
        </div>
        }
      </div>
    </div>
  );
};

export default Banner;
