import React from 'react'
import Banner from '../../components/Banner/Banner'
import './whiteLimousine.css'
import bannerImg from '../../images/limousine.jpg'
import Benefits from '../../components/Benefits/Benefits'
import { motion } from 'framer-motion';

const points = [
  {
    text: "White limousine service provides a stylish and easy way to get to your destination."
  },
  {
    text: "It eliminates the downtime of waiting for public transportation."
  },
  {
    text: "Limousine service is especially helpful for long trips or international airport transportation."
  },
  {
    text: "Professional and attentive drivers are provided for the duration of your journey."
  },
  {
    text: "White limo service is available 24/7 and can be booked for any event, from wine tours to business trips."
  },
  {
    text: "White cabs offer a variety of limousine services."
  },{
    text: "A White limousine service is a must-have for weddings in Edmonton."
  },
  {
    text: "Limousines are popular among dignitaries and wealthy individuals."
  },
  {
    text: "The most common type of limousine is the stretch limo with seating for up to 8 people and their luggage."
  },
]

const WhiteLimousine = () => {
  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} className='home container'>
      <Banner comp="home" title="White Limousine Service" description="Looking for a safe and professional white limo service in Spruce Grove, AB? Look no further! We offer on-demand transportation services with chauffeured vehicles of your choice. Our eco-friendly, electric-powered cars feature free WiFi, making them perfect for any special event or holiday.
" bannerImg={bannerImg} />
      <Benefits title="Benefits of White Limousine Service" description="A White limousine service is just that – a Service that can be hired by someone. They are typically vehicles designed to fit many traveller and provide excellent luxury features. So they’re perfect for parties or special occasions." points={points}  />
    </motion.div>
  )
}

export default WhiteLimousine