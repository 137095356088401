import React from 'react'
import Banner from '../../components/Banner/Banner'
import Benefits from '../../components/Benefits/Benefits'
import Faq from '../../components/Faq/Faq'
import bannerImg from '../../images/banner8.svg'
import { motion } from 'framer-motion';

const TaxiService = () => {
  const faq = [
    {
      title: "24 hours 7-week taxi is available in Canada?",
      desc: "Yes, White cabs provide taxi service 24/7 week in Canada. You can book our taxi any time anywhere in Canada. Our drivers reached before your given time."
    },
    {
      title: "In which area is taxi service available 24/ 7 week?",
      desc: "White Cabs provides cab service in Spruce Grove, Stony Plain, ST. Albert, Parkland, Morinville, Sherwood, Fort sasquatch, Edmonton, Airports, and more."
    },
    {
      title: "Can I book a taxi from the airport at any time in Canada?",
      desc: "Yes, Passengers can book taxis for the airport as well as from the airport to anywhere at any time in Canada. White Cabs provides a 24/7 taxi service anywhere in Canada."
    },
    {
      title: " Why choose the White Cabs taxi? ",
      desc: "White cabs are the ones to blame. They’re a few minutes early. We have one-way and round-trip tickets at a discount in Canada. Call 780 203 8000 to reserve a cab."
    },
    {
      title: "During a lockdown, can I get a sanitized taxi? ",
      desc: "Passengers in Edmonton, Morinville, Sherwood, Stony Plain, and Spruce Grove can, of course, hire sterilized cabs while on lockdown. For the sake of passenger safety, our driver cleaned their cab every day."
    }
    
  ]
  const points = [
    {
      text: "White Cabs taxi service is very well."
    },
    {
      text: "Every cab is sanitized"
    },
    {
      text: "Its budgets friendly cab."
    },
    {
      text: "Drivers are honest and punctual."
    },
    {
      text: "Sedan, SUV, and Tempo taxis are available."
    },
    {
      text: "Drivers wash their taxis every day."
    },
    {
      text:"White cabs provide service for Stony plain taxi, Spruce Grove taxi, Edmonton taxi, St. Albert and more town"
    }
  ]
  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} className='taxiservice container'>
      <Banner comp="home" title="24/7 taxi service" description="White Cabs offers 24/7 taxi services in Canada. Call 780 203 8000 or book online at whitecabs.ca. Our professional drivers provide safe travel day or night. Visit our website to learn more and book a sanitized taxi, including Spruce Grove taxis. We offer corporate and personal accounts for your convenience." 
      bannerImg={bannerImg}
      />
      <Benefits title="Why should you use White Cabs?" points={points}  />
      <Faq title="Frequently Asked Questions" data={faq} />
    </motion.div>
  )
}

export default TaxiService