import React from 'react'
import Banner from '../../components/Banner/Banner'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/car.svg'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import DownloadBanner from '../../components/DownloadBanner/DownloadBanner';

const StonyTaxi = () => {
  const demandService= [
    {
      title: "Enjoy your Journey with White Cabs",
      desc: "Book a taxi with White Cabs for an enjoyable journey, and focus on your necessary arrangements while we take care of the transportation. Our drivers will reach the agreed location at the appointed time, making the experience relaxing. Booking a Stony Plain taxi is quick and easy online or from home, and senior citizen discounts are available."
    },
    {
      title: "Sanitized and Secure taxi in Stony Plain",
      desc: "If you are hesitant to use public transportation due to overcrowding and hygiene concerns during Covid-19, consider hiring a clean and sanitized cab from Stony Plain Taxi for a hassle-free ride. Our drivers are reliable and committed to getting you to your destination on time. Each taxi undergoes a proper sanitization process after every ride to ensure the safety of passengers. Travel with confidence and without worries about getting infected."
    }
  ]
  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} className='stonytaxi container'>
           <Banner comp="home" title="Stony Plain Taxi" description="Try the efficient, reliable, and comfortable cab services offered by Stony Plain for transportation needs in the city. Whether for daily commutes or weekend trips, Stony Plain offers a variety of taxi services to choose from according to personal preferences. Luxurious cars are available to make your travel comfortable. Visit the website to book a taxi that matches your requirements." 
      bannerImg={bannerImg}
      />
<div className="applink">

<DownloadBanner />

      </div>
       <Services data={demandService} />
    </motion.div>
  )
}

export default StonyTaxi