import React from "react";
import './benefits.css'
import { TiTick } from "react-icons/ti";

const Benefits = ({ title, description, points }) => {
  return (
    <div className="benefits__container">
      <div className="benefits__left">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="benefits__right">
        {points.map((elm,index) => (
          <div key={index} className="benefits__point">
            <TiTick /> <h4>{elm.text}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Benefits;
