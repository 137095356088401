import React from 'react'
import Banner from '../../components/Banner/Banner'
import Benefits from '../../components/Benefits/Benefits'
import bannerImg from '../../images/car2.svg'
import './edmontonTaxi.css'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'
import DownloadBanner from '../../components/DownloadBanner/DownloadBanner'

const EdmontonTaxi = () => {

  const points = [
    {
      text: "Car rental services."
    },
    {
      text: "One way taxi Services."
    },
    {
      text: "Round tour taxi Services."
    },
    {
      text: "Door to Door taxi service."
    },
    {
      text: "Airport transportation services."
    }
  ]

  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} className='edmontontaxi container'>
       <Banner comp="home" title="Edmonton Taxi Service" description="White Cabs offers taxi services in Edmonton at nominal fares, including one-way, round trip, door-to-door, and airport transfers. We also provide Wheel Chair Van service for those who are unable to walk. Our well-maintained taxis provide a comfortable and joyful ride for a memorable experience in Edmonton.
" bannerImg={bannerImg} />
<div className="applink">

<DownloadBanner />

      </div>
<Benefits title="Our Taxi Service in Edmonton "  points={points}  />
    </motion.div>
  )
}

export default EdmontonTaxi