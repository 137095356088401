import React from 'react'
import './loader.css' 

const Loader = () => {
  return (
    <div className='loader container'>
        <div className="loader__road">
            <div className="loader__taxi">
                <div className="light"></div>
                <span>
                    <b></b>
                    <i></i>
                </span>
            </div>
        </div>
    </div>
  )
}

export default Loader