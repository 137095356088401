import React from 'react'
import './footer.css'
import logo from '../../images/logo.png';
import { Link } from 'react-router-dom';

const footerLinks = [
  {
      text: "Home",
      link: "/"
  },
  {
      text: "About Us",
      link: "/about"
  },
  {
      text: "Booking Online",
      link: "/booking-online-taxi"
  },
  {
      text: "Service",
      link: "/24-7-taxi"
  },
  {
      text: "Location",
      link: "https://maps.app.goo.gl/bEihdiiEkBLPFGRG9"
  },
  {
      text: "Blog",
      link: "/blogs"
  },
  {
      text: "Contact Us",
      link: "/contact-us"
  }
]

const Footer = () => {
  return (
    <div className='footer container' >
      <div className="footer__links">
        <div className="footer__links_logo">
          <img src={logo} alt="" />
          <p>White Cab offers a range of cab services with expert chauffeurs, luxury vehicles, and premium services. Round trip taxi services are available at affordable fares.</p>
        </div>
        <div className="footer__links_div" >
          <h4>Links</h4>
          <ul className="footer__links">
                {
                    footerLinks.map((elm, index) => (
                        <li key={index} className='footer__link'><Link to={elm.link}>{elm.text}</Link></li>
                    ))
                }
            </ul>
        </div>
        <div className="footer__links_div">
          <h4>Get in touch</h4>
          <Link to="#">30-35 BOULDER BLVD STONY PLAIN AB T7Z 1V6 CANADA</Link>
          <Link to="tel:+17802038000">+1 780 203 8000</Link>
          <Link to='mailto:manager@whitecabs.ca'>manager@whitecabs.ca</Link>
          <Link to='https://maps.app.goo.gl/bEihdiiEkBLPFGRG9'>View in Map</Link>
        </div>
      </div>
      <div className="footer__copyright">
        <p>2023 White Cabs. All Rights Reserved</p>
      </div>
    </div>
  )
}

export default Footer