import React, { useState } from "react";
import "./booking.css"
import { BsFillArrowRightCircleFill,BsFillArrowLeftCircleFill} from "react-icons/bs";
import { motion } from 'framer-motion';
import Banner from "../../components/Banner/Banner";
import bannerImg from '../../images/banner2.svg'

export const SliderData = [
    {
        text: "Call 780-203-8000 to book a ride or use the online booking system."
    },
    {
        text: "To book a taxi online, provide your name, pickup location (address), destination, date and time of the journey, and phone number."
    },
    {
        text: "With the use of modern technology, customers can easily book their taxis from their phones, laptops, or computers with an internet connection."
    },
    {
        text: "White Cabs offers a variety of taxi services, including Spruce Grove Taxi, shopping taxi service, Parkland taxi service, Business Taxi Service, and airport taxi service."
    },
    {
        text: "Booking a taxi online is a convenient and easy way to book your ride from the comfort of your own home."
    },
    {
        text: "There are no extra charges for booking online, and customers can also learn more about White Cabs by visiting their website."
    },
]

const Booking = () => {
    const slide = SliderData;
    const [current, setCurrent] = useState(0);
    const length = slide.length;
  
    const nextSlide = () => {
      setCurrent(current === length - 1 ? 0 : current + 1);
    };
  
    const prevSlide = () => {
      setCurrent(current === 0 ? length - 1 : current - 1);
    };
  
    if (!Array.isArray(slide) || slide.length <= 0) {
      return null;
    }

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="booking container"
      >
        <Banner
          comp2="about"
          comp="home"
          title="You can also download our App"
          description="White Cabs in working for passengers from 10 years later. Our taxi service is fast as well as reliable. So customers book taxi online with us and know about our services."
          bannerImg={bannerImg}
        />
        <div className="booking__container">
          <div className="booking__left">
            <h2>Here are the steps to book a taxi online with White Cabs.</h2>
            <div className="steps__container">
              <BsFillArrowLeftCircleFill
                className="left-arrow"
                onClick={prevSlide}
              />
              {SliderData.map((slide, index) => {
                return (
                  <div
                    className={index === current ? "slide active" : "slide"}
                    key={index}
                  >
                    {index === current && (
                      <>
                        {/* <div className="steps__container-overlay"></div> */}
                        <div className="steps">
                          <h3>{slide.text}</h3>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
              <BsFillArrowRightCircleFill
                className="right-arrow"
                onClick={nextSlide}
              />
            </div>
          </div>
          {/* <div className="bookingForm" id="booking">
            <h2>Book Now</h2>
            <div className="middle-side">
              <form id="booking-form">
                <div className="input-box">
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter your Pickup location"
                    required
                  />
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    id="address"
                    placeholder="Enter your dropoff address"
                    required
                  />
                </div>
                <div className="input-box-combo">
                  <div className="input-box">
                    <input type="date" id="date" required />
                  </div>
                  <div className="input-box">
                    <input
                      type="time"
                      id="email"
                      placeholder="Enter your dropoff address"
                      required
                    />
                  </div>
                </div>
                <div className="input-box">
                  <select
                    id="email"
                    placeholder="Enter your dropoff address"
                    required
                  >
                    <option value="car">Car</option>
                    <option value="Van">Van</option>
                  </select>
                </div>
                <div className="input-box-combo">
                  <div className="input-box">
                    <input type="checkbox" required /> <h3>Return</h3>
                  </div>
                  <div className="input-box">
                    <input type="checkbox" required /> <h3>Add Via</h3>
                  </div>
                </div>
                <div className="button">
                  <input type="submit" value="Get A Quote" />
                </div>
              </form>
            </div>
          </div> */}
        </div>
      </motion.div>
    </>
  );
};

export default Booking;
