import React from 'react'
import "./blogDetail.css"
import { useParams } from 'react-router-dom'

const cards = [
    {
        img: 'https://cdn.pixabay.com/photo/2014/01/04/13/34/taxi-238478_960_720.jpg',
        title: 'The Benefits of Using a Cab Service for Your Daily Commute',
        desp: 'In this post, you can discuss the convenience and cost-effectiveness of using a cab service for regular transportation needs. ',
        p1: 'Gone are the days of waiting at crowded bus stops or dealing with the hassles of maintaining a personal vehicle. With a cab service at your fingertips, you can enjoy unparalleled convenience. No more worrying about parking spaces or navigating through heavy traffic – just book a cab, and it arrives at your doorstep, ready to whisk you away to your destination.',
        p2:"Contrary to common belief, using a cab service can be a remarkably cost-effective choice. Factor in the expenses associated with owning a car, such as maintenance, fuel, insurance, and parking fees, and you'll realize that cabs offer a more budget-friendly alternative. We'll provide you with a detailed cost analysis to showcase the potential savings.",
        p3:"Time is precious, and a reliable cab service helps you make the most of it. Say goodbye to the stress of commuting and enjoy a comfortable ride where you can catch up on work, read a book, or simply relax. We'll discuss how cab services optimize your daily schedule and contribute to a stress-free commute.",
        link: 'the-benefits-of-using-a-cab-service-for-your-daily-commute'
    },
    {
        img:"https://cdn.pixabay.com/photo/2020/02/06/08/49/taxi-4823433_960_720.jpg",
        title: 'Tips for Finding the Best Cab Service in Your Area',
        desp: 'This post can focus on helping readers find the right cab service to fit their needs. You can discuss factors to consider such as pricing, reliability compare different services.',
        p1:"When it comes to selecting a cab service, pricing is a fundamental consideration. We'll provide you with valuable insights into understanding cab fare structures, including flat rates, metered fares, and surge pricing during peak hours. Discover tips on how to estimate your ride costs and even potential cost-saving strategies. Whether you're a budget-conscious traveler or looking for a premium experience, we'll help you navigate the pricing landscape to find the best fit for your wallet. ",
        p2:"Reliability is a top priority when choosing a cab service. We'll discuss the importance of reliability in ensuring that your rides arrive promptly and that you reach your destination without unnecessary delays. Furthermore, we'll explore safety measures that reputable cab services implement, from driver background checks to vehicle maintenance. Your peace of mind is paramount, and we'll guide you on how to identify cab services that prioritize passenger safety.",
        p3:"The cab service market is diverse, with various providers offering unique features and advantages. In this section, we'll provide a comprehensive guide to comparing different cab services in your area. Learn how to assess user reviews and ratings, service coverage areas, and the availability of additional amenities such as wheelchair-accessible vehicles or luxury options. By the end of this blog post, you'll have the tools and knowledge to make an informed choice and find the best cab service that aligns with your preferences and requirements.' ",
        link: 'tips-for-finding-the-best-cab-service-in-your-area'
    },
    {
        img: 'https://cdn.pixabay.com/photo/2016/03/18/14/11/city-1265055_960_720.jpg',
        title: 'How Technology is Changing the Cab Service Industry',
        desp: ' In this post, you can explore the ways in which technology is revolutionizing the cab service industry. This can include discussing things like ride-hailing apps, GPS tracking, and the use of electric or hybrid vehicles.',
        p1:"One of the most noticeable shifts in the cab service industry is the proliferation of ride-hailing apps. We'll take you behind the scenes of these user-friendly applications, showcasing how they've revolutionized the way we book and experience cab services. From real-time vehicle tracking to seamless payment options, ride-hailing apps have made transportation more convenient and accessible than ever before. Explore how these apps have not only simplified the booking process but have also improved overall customer experience.",
        p2:"GPS technology has become a game-changer for cab services. We'll delve into the advantages of GPS tracking, both for drivers and passengers. Discover how it ensures accurate and efficient routes, reducing travel time and minimizing traffic-related stress. Moreover, GPS tracking enhances safety and security, allowing service providers and passengers to monitor the journey in real time. We'll showcase the ways in which GPS has transformed cab services into a more reliable and efficient mode of transportation.",
        p3:"As environmental consciousness grows, the cab service industry is embracing sustainability through the adoption of electric and hybrid vehicles. We'll explore the eco-friendly initiatives taken by cab service providers and how these vehicles contribute to reducing carbon emissions. Learn about the benefits of electric and hybrid cabs, from lower operating costs to quieter and cleaner urban transportation. Discover how these technological advancements align with the green transportation movement and pave the way for a more sustainable future.",
        link: 'how-technology-is-changing-the-cab-service-industry'
    },
    {
        img:"https://cdn.pixabay.com/photo/2016/12/25/19/23/new-york-1930656_960_720.jpg",
        title: 'The Importance of Safety in Cab Services',
        desp: 'This post can focus on the crucial role that safety plays in the cab service industry. You can discuss the steps that cab services take to ensure the safety of their passengers, such as driver background checks, vehicle maintenance protocols, and adherence to traffic laws. ',
        p1:"The safety journey begins with the selection of reliable and trustworthy drivers. We'll shed light on how cab services prioritize passenger safety by conducting thorough background checks on potential drivers. Learn about the comprehensive screening process that assesses a driver's criminal history, driving record, and other relevant credentials. Discover why this step is essential for building trust and ensuring that passengers are in capable hands from the moment they step into a cab.",
        p2:"A well-maintained vehicle is a key element of a safe cab service. We'll take you behind the scenes of vehicle maintenance protocols that cab companies adhere to rigorously. From regular inspections to timely repairs and maintenance, these procedures guarantee that every cab on the road meets high safety standards. Explore how these measures contribute to a smooth and secure journey, minimizing the risk of breakdowns or accidents that can compromise passenger safety.",
        p3:"Cab services are committed to upholding traffic laws and regulations to the letter. We'll discuss how strict adherence to these laws not only ensures the safety of passengers but also contributes to the overall well-being of communities. Discover the various safety measures and practices that cab companies implement, from speed limits and seatbelt usage to ensuring driver compliance with laws governing cell phone usage while driving. Safety isn't just a priority; it's a commitment to responsible and lawful operation. ",
        link: 'the-importance-of-safety-in-cab-services'
    }
]

const BlogDetail = () => {

    const text =  window.location.href;
    const parts = text.split('/blogs/');
    const url = parts[1];
    let details = {}

    cards.forEach(element => {
        if(element.link === url){
            details = {...element}
        }
    });

    console.log(details);

  return (
    <div className='blog-detail container'>
        <img src={details.img} alt="" />
        <div className="tags"><span className='cabs-tag'>Cabs</span> <span className='cabs-span'>16 April</span></div>
        <h2 className='title'>{details.title}</h2>
        <div className='desp'>
            <p>{details.p1}</p>
            <p>{details.p2}</p>
            <p>{details.p3}</p>
        </div>
        
    
    </div>
  )
}

export default BlogDetail