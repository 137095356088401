import React from 'react'
import Post from '../../components/Post/Post'
import "./blog.css"

const cards = [
    {
        img: 'https://cdn.pixabay.com/photo/2014/01/04/13/34/taxi-238478_960_720.jpg',
        title: 'The Benefits of Using a Cab Service for Your Daily Commute',
        desp: 'In this post, you can discuss the convenience and cost-effectiveness of using a cab service for regular transportation needs. ',
        link: 'the-benefits-of-using-a-cab-service-for-your-daily-commute'
    },
    {
        img:"https://cdn.pixabay.com/photo/2020/02/06/08/49/taxi-4823433_960_720.jpg",
        title: 'Tips for Finding the Best Cab Service in Your Area',
        desp: 'This post can focus on helping readers find the right cab service to fit their needs. You can discuss factors to consider such as pricing, reliability compare different services.',
        link: 'tips-for-finding-the-best-cab-service-in-your-area'
    },
    {
        img: 'https://cdn.pixabay.com/photo/2016/03/18/14/11/city-1265055_960_720.jpg',
        title: 'How Technology is Changing the Cab Service Industry',
        desp: ' In this post, you can explore the ways in which technology is revolutionizing the cab service industry. This can include discussing things like ride-hailing apps, GPS tracking, and the use of electric or hybrid vehicles.',
        link: 'how-technology-is-changing-the-cab-service-industry'
    },
    {
        img:"https://cdn.pixabay.com/photo/2016/12/25/19/23/new-york-1930656_960_720.jpg",
        title: 'The Importance of Safety in Cab Services',
        desp: 'This post can focus on the crucial role that safety plays in the cab service industry. You can discuss the steps that cab services take to ensure the safety of their passengers, such as driver background checks, vehicle maintenance protocols, and adherence to traffic laws. ',
        link: 'the-importance-of-safety-in-cab-services'
    }
]

const Blog = () => {
  return (
    <div className='blog container'>
        <h2 className='title'>Our Blogs</h2>
        <div className="blog__container">
        {
           cards.map((elm,i)=>(
            <Post key={i} elm={elm} />
           )) 
        }
        </div>
    </div>
  )
}

export default Blog