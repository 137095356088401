import React from 'react'
import Banner from '../../components/Banner/Banner'
import Benefits from '../../components/Benefits/Benefits'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/airport.jpg'
import { motion } from 'framer-motion';

const points = [
  {
    text: "White Cabs provides stress-free and memorable travel experience to customers."
  },
  {
    text: "White Cabs offers high-quality services to clients including airport taxi services to various destinations such as Stony Plan and Edmonton."
  },
  {
    text: "White Cabs values punctuality and ensures customers arrive at their destination comfortably and on time."
  },
  {
    text: "Customers can reserve a cab for their destination through the airport cab service."
  },
  {
    text: "White Cabs drivers are skilled and experienced professionals."
  },
  {
    text: "White Cabs airport taxi service is always available outside railway stations for quick and early arrivals."
  }
]

const demandService= [
  {
    title: "Sanitized Airport Taxi Service",
    desc: "White cabs prioritize passenger safety and provide sanitized airport taxi service in Canada amidst the ongoing pandemic. Our reliable and high-quality cab services ensure a stress-free and secure journey at reasonable fares."
  },
  {
    title: "24/7 Airport Taxi Service with Luxury Fleet and On-Demand Delivery",
    desc: " Our airport taxi service is available 24/7, with punctual drivers who arrive early. Book a top-notch taxi with our large fleet of vehicles, including luxury cabs for tourists. We also offer on-demand delivery service. White Cab provides corporate and personal accounts for those who book taxis permanently. Our airport cab service is available for passengers arriving at the metro."
  },
  {
    title: "Safe and Secure Taxi Service",
    desc: "White cabs prioritize safety during Covid-19 by providing disinfected and clean cabs, and drivers wearing masks and gloves. They sanitize and clean their cabs before and after each trip, while maintaining social distancing and checking temperature for passenger and driver safety. Their airport taxi service is available 24/7 and ensures safe transportation, even at night."
  },
]

const AirportTaxi = () => {
  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} className="airportTaxi container"> 
    <Banner comp="home" title="Airport Taxi Service" description="Looking for an airport taxi service in Canada? Call 780 203 8000 to book with White Cabs. Our flat-rate taxis are available for single passengers or groups of any size, with sedans, SUVs, and Tempo options. We also offer taxi services for hotel, office, personal use, restaurants, and more. Book your airport taxi to any destination anytime." 
      bannerImg={bannerImg}
      />
      <Benefits title="Benefits of Booking Airport Taxi service" points={points}  />
      <Services title="Our Services" data={demandService} />
      </motion.div>
  )
}

export default AirportTaxi