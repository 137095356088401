import React, { useState } from "react";
import "./header.css";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import { HiOutlineBars3BottomRight } from "react-icons/hi2";
import { BsFillMoonFill, BsFillSunFill } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {IoCall} from 'react-icons/io5';

const headerLinks = [
  {
    text: "Home",
    link: "/",
  },
  {
    text: "About Us",
    link: "/about",
  },
  {
    text: "Booking Online",
    link: "/booking-online-taxi",
  },
  {
    text: "Service",
    link: "#",
    sublink: [
      {
        text: "White Limousine Service",
        link: "/white-limousine-service",
      },
      {
        text: "Hotel Taxi Service",
        link: "/hotel-taxi",
      },
      {
        text: "On Demand Delivery",
        link: "/on-demand-delivery",
      },
      {
        text: "Shopping Taxi Service",
        link: "/shopping-taxi-service",
      },
      {
        text: "Wheelchair Van",
        link: "/wheelchair-van",
      },
      {
        text: "Airport Taxi Service",
        link: "/airport-taxi",
      },
      {
        text: "Business Taxi Service",
        link: "/business-taxi",
      },
      {
        text: "24/7 taxi service",
        link: "/24-7-taxi",
      },
      {
        text: "Flat Rate Taxi Service",
        link: "/flat-taxi",
      },
    ],
  },
  {
    text: "Location",
    link: "#",
    sublink: [
      {
        text: "Spruce Grove Taxi",
        link: "/cab-spruce-taxi",
      },
      {
        text: "Stony Plain Taxi",
        link: "/stony-taxi",
      },
      {
        text: "Edmonton Taxi",
        link: "/edmonton-taxi-service",
      },
      {
        text: "Parkland Taxi",
        link: "/parkland-taxi-services",
      },
    ],
  },
  {
    text: "Blog",
    link: "/blogs",
  },
  {
    text: "Contact Us",
    link: "/contact-us",
  },
];

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(true);
  const [modeToggle, setModeToggle] = useState(true);

  const lightMode = () => {
    document.body.classList.add("active");
    setModeToggle(false);
  };

  const darkMode = () => {
    document.body.classList.remove("active");
    setModeToggle(true);
  };

  if(!toggleMenu)  {
    document.body.style.overflow= "hidden";
    document.body.style.height= "100vh";
  }else{
    document.body.style.overflow= "inherit";
    document.body.style.height= "100%";
  }

  return (
    <div className="header container">
      <div className="header__left">
        <Link to="/" className="header__logo">
          <img src={logo} alt="logo" />
        </Link>
        {modeToggle ? (
          <div className="theme__toggler" onClick={lightMode}>
            <BsFillSunFill />
          </div>
        ) : (
          <div className="theme__toggler" onClick={darkMode}>
            <BsFillMoonFill />
          </div>
        )}
        <Link to="tel:+17802038000" className="float">
        <IoCall className='my-float' />
      </Link>
      </div>
      <ul className={toggleMenu ? "header__links" : "header__links active"}>
        {headerLinks.map((elm, index) => (
          <li key={index} className="header__link">
            {!elm.sublink ? (
              <Link
                to={elm.link}
                onClick={() => {
                  console.log("link click");
                  setToggleMenu(true);
                }}
              >
                {elm.text}
              </Link>
            ) : (
              <div style={{"color":"var(--black)","cursor":"pointer"}} onClick={() => console.log("div click")}>{elm.text}</div>
            )}

            {elm.sublink && (
              <ul className="dropdown">
                {elm.sublink.map((innerElem, index) => (
                  <li key={index}>
                    <Link to={innerElem.link} onClick={() => {
                    console.log(innerElem.text);
                    setToggleMenu(true);
                  }}>{innerElem.text}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <div className="menu-btn">
        {toggleMenu ? (
          <HiOutlineBars3BottomRight onClick={() => setToggleMenu(false)} />
        ) : (
          <AiOutlineCloseCircle onClick={() => setToggleMenu(true)} />
        )}
      </div>
    </div>
  );
};

export default Header;
