import React from 'react'
import Banner from '../../components/Banner/Banner'
import Faq from '../../components/Faq/Faq'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/banner7.svg'
import { motion } from 'framer-motion';

const businessService= [
  {
    title: "Book Business Taxi Service with us",
    desc: "If you are a businessman and want to book a Taxi, call us and we can arrange a cab to your office. Or if you wish to use our Business taxi service frequently, our executive will assist you. There are discounts available but the quality of service is unmatched for Business Cab Service. Monthly and quarterly packages are available for regular customers. You are only a call away to avail of this service."
  },
  {
    title: "Business Taxi Service Use from Airport",
    desc: "If you are coming from outside the country and need a Business Taxi Service with airport pick-up? So don’t worry about that, our cab will pick you up. We will provide you with Business Cab Service from the Airport to the Hotel and to the Business Place comfortably. If you need to be somewhere on time, then you can hire our Online taxi right now without wasting time. The taxi will be on time."
  },
  {
    title: "Business Area’s",
    desc: "Canada is a larger country than other countries, and it has a wider range of business options. There are many business personalities in Canada with their businesses across the country. For this reason, they travel all over and use Business Cab Services. Traveling to every area requires them to be at ease and in a relaxed position. Business Cab is design according to their comfort level. So the customer will feel a Luxury ride and will not be tired in long-distance Business Areas."
  },
  {
    title: "Safety and Cleaning ",
    desc: "There is a mandatory Safety and Cleaning course during Covid-19. So our Business Taxi  Service is disinfected, clean, and safe. During the journey, our staff wears gloves and masks. The staff maintains a distance between clients and the cab before and after every ride. As part of our business cab service, we check the temperature of the rider and the passengers for safety reasons."
  },
]

const faq = [
  {
    title: "Are you looking for Business cab?",
    desc: "White cabs provide a business Cab with business-class features."
  },
  {
    title: "Need a Business Cab Service from Airport?",
    desc: "You are in the right direction, we provide the business cab service pick-up from the airport. "
  },
]

const BusinessTaxi = () => {
  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} className='businesstaxi container'>
      <Banner comp="home" title="Business Taxi Service" description="We offer a luxury fleet of business taxis to suit your needs, including sedans, SUVs, and executive cars. Our standalone service provides Personal Business Cab, Chauffeured Town Cars, and Corporate VHA Cars. Hire us for shopping, office use, personal use, one-day pickups, hotels, airports, restaurants, hospitals, and tourism. Book online or call us 24/7." 
      bannerImg={bannerImg}
      />
        <Services data={businessService} />
      <Faq title="Frequently Asked Questions" data={faq} />
    </motion.div>
  )
}

export default BusinessTaxi