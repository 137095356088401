import React from 'react'
import Card from '../../components/Card/Card'
import { TiTickOutline } from 'react-icons/ti'
import { FiHelpCircle } from 'react-icons/fi';
import { TbMultiplier2X } from 'react-icons/tb';
import { FaTelegramPlane } from 'react-icons/fa';
import { AiFillLock, AiOutlineBulb } from 'react-icons/ai';
import './home.css'
import Faq from '../../components/Faq/Faq';
import Carousal from '../../components/Carousal/Carousal';
import { Link } from 'react-router-dom';
import bannerImg1 from '../../images/banner9.svg'
import bannerImg2 from '../../images/taxi2.jpg'
import { motion } from 'framer-motion';
import Banner from '../../components/Banner/Banner';
import DownloadBanner from '../../components/DownloadBanner/DownloadBanner';

const SliderData = [
  {
      title: 'White cabs -  Spruce Grove Taxi & Stony Plain',
      desp: 'White cabs started 7 years ago as cooperative taxi. White Cabs is a 24/7 cooperative taxi company that prioritizes customer satisfaction.',
      image: bannerImg1,
      button: <Link to="https://whitecabs.webbooker.icabbi.com/"><button>BOOK NOW</button></Link>
  },
  {
    title: 'White cabs -  Spruce Grove Taxi & Stony Plain',
    desp: ' Their fleet is safe, reliable, and well-maintained, and drivers undergo rigorous training to ensure a pleasant and trouble-free trip. ',
    image: "https://cdn.pixabay.com/photo/2023/03/16/13/09/taxi-7856666_960_720.jpg",
    button: <Link to="https://whitecabs.webbooker.icabbi.com/"><button>BOOK NOW</button></Link>
  },
  {
    title: 'White cabs -  Spruce Grove Taxi & Stony Plain',
    desp: 'They offer special vehicles for disabled individuals, an affordable parcel delivery service, and comply with strict standards of upkeep and cleanliness.',
    image: bannerImg2,
    button: <Link to="https://whitecabs.webbooker.icabbi.com/"><button>BOOK NOW</button></Link>
  }
]

const cards = [
  {
    icon: <TiTickOutline />,
    title: "Expert drivers",
    des: "White Cabs provide dependable and confident service to and from the airport and the surrounding area with drivers who are familiar with the all routes."
  },
  {
    icon: <AiOutlineBulb />,
    title: "Recent technology",
    des: "We also offer one-on-one service through our call center and management team, as well as convenient automated phone, web, and app booking options for our Stony Plain/Spruce Grove taxi service."
  },
  {
    icon: <FiHelpCircle />,
    title: "Advance help",
    des: "We have many friendly, helpful drivers who are aware of our city’s culture, history, and attraction and are able to guide you to the best places to go and see."
  },
  {
    icon: <TbMultiplier2X />,
    title: "Multiplicity",
    des: "We provide a variety of taxi services and value our clients. Our drivers are skilled and capable of handling any situation, providing top-notch service. Experience first-class service with Stony Plain's flat rate taxis from White Cabs."
  },
  {
    icon: <FaTelegramPlane />,
    title: "Taxi service for airport",
    des: "White Cabs also offers professional airport taxi services available 24/7. Our airport taxi service provides a wide range of vehicle options to meet your specific needs. Easily schedule your airport pickup with us."
  },
  {
    icon: <AiFillLock />,
    title: "Fast and secure",
    des: "As Spruce Grove and Stony Plain's premier taxi service, we provide a safe and reliable option for both personal and business travel needs. Our professional drivers ensure a smooth and secure transportation experience from start to finish."
  }
]

const faq = [
  {
    title: "Looking for Spruce Grove Taxi?",
    desc: "White cabs provide flat rate taxi in spruce grove and stony plain. We provide quick service to our passengers. Because we know the value of time."
  },
  {
    title: "How do you get a taxi in Spruce Grove and Stony Plain?",
    desc: "To book spruce grove and stony plain taxi, you can make your booking by our website white cabs or you can call us "
  },
  {
    title: "How to hire a cab in Spruce Grove?",
    desc: "Even Travelers can heir cabs in stony plain and spruce grove by our website or calling our White cabs services contact number "
  },
  {
    title: "How much is a cab from Spruce Grove to Edmonton?",
    desc: "Well, Spruce Grove to Edmonton price depends on how much your travel distance."
  },
  {
    title: "What is taxi fare finder?",
    desc: "We know passenger live in taxi fare anytime, anywhere. Because 1000 taxis available in your spruce grove and stony plain. But every taxi have not flat rate. But our estimates are fate rate taxi based on local taxi rate & actual taxi prices."
  }
]

const Home = () => {
  
  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}}
    className='home container'>
      {/* <Banner comp="home" title="White cabs -  Spruce Grove Taxi & Stony Plain" description="White Cabs offers premium limousine services in Spruce Grove and surrounding areas, prioritizing your needs for timely arrival at meetings, events, or appointments.
" bannerImg={bannerImg} /> */}
      {/* <Carousal SliderData={SliderData} /> */}
      <Banner comp="home" title="Looking for Spruce Grove Taxi?" description="White cabs provide flat rate taxi in spruce grove and stony plain. We provide quick service to our passengers. Because we know the value of time.
" bannerImg={bannerImg1} />
  <DownloadBanner homeBanner='true' />
{/* <div className="applink">
      <Link to="https://apps.apple.com/gb/app/white-cabs/id1637899683" className="download1"></Link>
      <Link to="https://play.google.com/store/apps/details?id=com.whitecabs.passengerapp" className="download2"></Link>
      </div> */}
      <div className='home__services'>
        <h2 className='home__services_title'>Why choose White Cabs <br /> for Spruce Grove Taxi ?</h2>
        <div className="home__services_container">
          {cards.map((card, index) => (
            <Card key={index} card={card} />
          ))
          }
        </div>
      </div>
      <Faq title="Frequently Asked Questions" data={faq} />

    </motion.div>
  )
}

export default Home