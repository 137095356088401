import React from 'react'
import './post.css'
import { Link } from 'react-router-dom'

const Post = ({elm}) => {
    return (
        <div className="blog__post">
            <div className="blog__post_img">
                <img src={elm.img} alt="post_img" />
            </div>
            <div className="blog__post_info">
                <div className="blog__post_category_date">
                    <div className="blog__post_category">
                        Cabs
                    </div>
                    <div className="blog__post_date">
                        16 April
                    </div>
                </div>
                <div className="blog__post_content">
                    <div className="blog__post_title">
                        {elm.title}
                    </div>
                    <div className="blog__post_desp">
                       {elm.desp}
                    </div>
                </div>
                <div className="blog__post_profile_more">
                    <div className="blog__post_profile">
                        <div className="blog__post_profile_img">
                            <img src="https://cdn.pixabay.com/photo/2017/11/10/05/46/group-2935521_960_720.png" alt="profile_img" />
                        </div>
                        <div className="blog__post_name">
                            Anonomous
                        </div>
                    </div>
                    <Link to={elm.link} className="blog__post_more">More..</Link>
                </div>
            </div>
        </div>
    )
}

export default Post