import React from 'react'
import { AiOutlineCaretDown } from 'react-icons/ai';
import './faq.css'

const Faq = ({data}) => {
    const handleClick = (e) => {
        e.currentTarget.classList.toggle('active');
     };
  return (
    <div className='faq__services'>
        <h2 className='faq__services_title'>Frequently Asked Questions</h2>
        <div className="faq__services_container">
          {
            data.map((elm, index) => (
              <div key={index} className='faq__box' onClick={handleClick}>
                <h4><span>{elm.title}</span> <span><AiOutlineCaretDown /></span> </h4>
                <p>{elm.desc}</p>
              </div>
            ))
          }
        </div>
      </div>
  )
}

export default Faq