import React from 'react'
import Banner from '../../components/Banner/Banner'
import Benefits from '../../components/Benefits/Benefits'
import Faq from '../../components/Faq/Faq'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/banner5.svg'
import './onDemand.css'
import { motion } from 'framer-motion';

const points = [
  {
    text: "Spruce Grove."
  },
  {
    text: "Stony Plain."
  },
  {
    text: "Edmonton."
  },
  {
    text: "Parkland."
  },
  {
    text: "Morinville."
  },
  {
    text: "St. Albert."
  },
  {
    text: "Sherwood Taxi."
  },
  {
    text: "Fort seschetch."
  }
]

const demandService= [
  {
    title: "Book and get to your hotel Taxi Service White cabs",
    desc: "Contact us at 780 203 8000 for a taxi from the airport to your lodge in Canada. We offer hotel taxis for tourists unfamiliar with the area and timely airport taxis. Our accommodating drivers will stop along the way if necessary or take a shortcut to ensure you arrive on time. Book now to enjoy your trip stress-free."
  },
  {
    title: "Book a hotel taxi service from Airport to the Motel",
    desc: "For affordable and high-quality hotels in Canada, contact us. Our hotels offer convenient hotel taxi services for tourists. Book a taxi by calling 780 203 8000 for stress-free transportation to your dream hotel. Our professional drivers will assist with your luggage and provide reliable and hassle-free service. We also offer taxi services for Sherwood Park, Parkland, and business needs. Book a cab now to explore different places with your family."
  },
]

const faq = [
  {
    title: "What is an on-demand delivery?",
    desc: "On-Demand delivery service is if you want deliver something from one place to another place."
  },
  {
    title: "How do you request an on-demand delivery?",
    desc: "For a safe and healthy shopping taxi service, White Cabs prioritizes passenger safety during the COVID-19 pandemic. Drivers sanitize their taxis after each ride, and wear masks along with passengers who must also wear a mask. Safe distancing and hand sanitation are practiced. Book with White Cabs for standardized and friendly service. Customers can rest assured of safety and excellent service provided by the caring drivers. "
  },
]

const OnDemand = () => {
  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} className='demand container'>
      <Banner comp="home" title="On Demand Delivery" description="We offer on-demand delivery taxis in Canada for a hassle-free experience. Contact us for courier service to pick up orders at home, with deliveries including food, shopping, and more. Our taxi service is available for business, shopping, hotels, airports, hospitals, restaurants, tourism, and even wheelchair van service at a flat rate." 
      bannerImg={bannerImg}
      />
       <Services title="Our on demand Services" data={demandService} />
      <Benefits title="Which part of the city is covered by the white cabs?" points={points}  />
      <Faq title="Frequently Asked Questions" data={faq} />
    </motion.div>
  )
}

export default OnDemand