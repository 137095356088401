import React from 'react'
import './card.css'

const Card = ({card}) => {

    return (
        <div className="home__services_box">
            <div className="home__services_box_icon">{card.icon}</div>
            <h3>{card.title}</h3>
            <p>{card.des}</p>
        </div>
    )
}

export default Card