import React from 'react'
import Banner from '../../components/Banner/Banner'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/car1.svg'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import DownloadBanner from '../../components/DownloadBanner/DownloadBanner';

const ParklandTaxi = () => {
  const parklandService= [
    {
      title: "Various Options available in Parkland Taxi Service",
      desc: "Choose from a variety of cabs for Parkland Taxi Service that fit your needs. Our modern and reliable cabs offer comfortable and luxurious interiors for a stress-free ride. Enjoy sightseeing during your taxi ride and make your journey comfortable and relaxing."
    },
    {
      title: "Offers and Discounts for Parkland Taxi Service",
      desc: "Enjoy Parkland taxi flat rate and special offer service for your next ride. Hire Cab from Parkland Alberta to anywhere in Edmonton as our Services are available for various locations. The company gives you various offers with amazing rates."
    },
    {
      title: "Professional and Experienced Chauffeurs",
      desc: "Our chauffeurs are well-trained professionals who value your time and needs. They wear proper uniforms and are equipped with mobile phones. They are knowledgeable about the routes and offer assistance with luggage. The drivers of Parkland Taxi are courteous and always ready to help you with the best possible service."
    },
    {
      title: "24/7 Taxi service is Available for parkland ",
      desc: "We offer 24/7 taxi service in Parkland at affordable rates. Book a ride from any location at any time. Our large number of punctual riders ensures a worry-free trip to your desired destination. Contact us at +1780 203 8000 to book your trip and choose from various packages."
    },
    {
      title: "Enjoy Your Journey",
      desc: "White Cabs offers affordable Parkland Cab Service with 24/7 car rental. Our taxi prices are fixed, and you can pay with credit or debit cards. We provide one-way and round-trip taxis to any destination in Parkland at a flat rate. Passengers can also enjoy nearby hotels and restaurants."
    },
    {
      title: "Can I Book Cheap Rate Cab",
      desc: "White cabs offer affordable 24/7 Parkland Cab Service with fixed prices and payment options through credit/debit cards. Passengers can book one-way or round-trip taxis to any destination within Parkland or other towns. Enjoy budget-friendly routes and delicious meals at top hotels and restaurants."
    },
  ]
  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} className='parklandtaxi container'>
       <Banner comp="home" title="Parkland Taxi Service" description="Book White Cabs for Parkland Taxi Service for timely pick-up and drop-off in luxurious cars. We provide 24/7 transportation services for Parkland Cab and Taxi with On-Demand Delivery options. Enjoy a comfortable Hotel Taxi ride at a nominal fare.
" bannerImg={bannerImg} />
<div className="applink">

<DownloadBanner />

      </div>
<Services title="Our Services" data={parklandService} />
    </motion.div>
  )
}

export default ParklandTaxi