import React from 'react'
import Banner from '../../components/Banner/Banner'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/car3.svg'
import './spruceTaxi.css'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'
import DownloadBanner from '../../components/DownloadBanner/DownloadBanner'

const SpruceTaxi = () => {
  const demandService= [
    {
      title: "Available 24/7",
      desc: "White Cab, Spruce Grove taxi, is available for you around-the-clock. Whether you need a cab in the wee morning hours or you need a ride in the middle of the night, simply call us or book a cab through our website. Our driver will be ready to pick you up in no time. Company also offer great discounts throughout the day when you book with us in Spruce Grove."
    },
    {
      title: "Focused on health and safety  ",
      desc: "We, at White Cabs, understand that safety is your priority; and, so we strongly focus on creating a safe and secure environment for you. To ensure that drivers clean and sanitize their cars regularly for Spruce Grove Taxi. Our drivers also follow a detailed protocol to ensure the highest standards of personal hygiene as well, including, frequently sanitizing their hands and wearing a mask at all times."
    },
    {
      title: "Ensuring your comfort",
      desc: "White Cabs values your comfort over everything else. We have a fleet of sedans, SUVs, private cabs, and, even, corporate cars available for your convenience. You are free to choose a vehicle of your preference and enjoy a comfortable ride, every time. "
    },
    {
      title: "Focused on health and safety  ",
      desc: "Additionally, our Spruce Grove taxi drivers are wonderful people. They are friendly and genuinely looking to offer you a seamless, hassle-free experience when you book a Spruce Grove taxi. Whether you want to enjoy a quiet, peaceful ride or would like to chat up with your driver about the city, our drivers are always happy to oblige. "
    },
  ]
  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} className='sprucetaxi container'>
      <Banner comp="home" title="Spruce Grove Taxi" description="White Cabs, a Spruce Grove taxi service, has been serving the city of Spruce Grove for the last 3 years. We offer quick and hassle-free taxi service in the area at an affordable rate. If you are looking for a safe, comfortable, and on-time ride, then give us a call on +1 (780) 203 8000 to book a cab. " 
      bannerImg={bannerImg}
      />
<div className="applink">

      <DownloadBanner />
      </div>
       <Services title="What makes White Cabs a special Spruce Grove taxi?" data={demandService} />
    </motion.div>
  )
}

export default SpruceTaxi