import React from "react";
import ContactUs from "../../components/ContactUs/ContactUs";
import Services from "../../components/Services/Services";
import "./contact.css";
import { motion } from 'framer-motion';

const contactService = [
  {
    title: "White Cabs Spruce Grove Taxi",
    desc: "Along with this, White cabs also provide efficient Spruce grove taxi service, Parkland taxi service as well as Stony plain taxi service. These cities are very amazing area and our services are also connected within these areas. The perfect way to get through everyday travel needs taxi. So, hire a taxi in the Spruce Grove from our booking online. You can also get us from Google Maps. You can also hire Edmonton taxi service for roaming.",
  },
  {
    title: "Query Section of White Cabs",
    desc: "The White Cab is here for you, if you have any comments, suggestions, or questions, please do not hesitate to contact us. Our high-quality office staff will help you and answer all your questions. Sometimes customers have doubts about how long our service is available. So let us tell you that our service is available 24 hours a day. If a passenger faces any problem even in the mid of the night that taxi is not available, then they do not need to panic. We are available day and night for our customers.",
  },
];

const Contact = () => {
  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} className="Contact container">
      <ContactUs />
      <Services title="Our Services" data={contactService} />
    </motion.div>
  );
};

export default Contact;
