import React from 'react'
import Banner from '../../components/Banner/Banner'
import Benefits from '../../components/Benefits/Benefits'
import Faq from '../../components/Faq/Faq'
import bannerImg from '../../images/banner4.svg'
import './shoppingTaxi.css'
import { motion } from 'framer-motion';

const ShoppingTaxi = () => {
  const points = [
    {
      text: "Its cost is affordable."
    },
    {
      text: "Drivers in white cabs are sassy."
    },
    {
      text: "Our taxis are hygienic and well-maintained."
    },
    {
      text: "Taxi that has been cleaned and disinfected"
    },
    {
      text: "Sedan, SUV, and Tempo taxis are available."
    },
    {
      text: "Make luggage services available for free."
    },
  ]
  
  const faq = [
    {
      title: "24 hours & 7 days available Shopping taxi service",
      desc: "White Cabs is a 24/7 shopping taxi service available in Canada. Book anytime for shopping, business meetings, hotels, or airport transportation. Enjoy flat rate service for any event, and timely arrivals even for midnight requests. Reserve a taxi at 780 203 8000 or whitecabs.ca for sedans, SUVs, and Tempo cabs."
    },
    {
      title: "During a lockdown, can we hire a sanitary taxi?",
      desc: "Just fill out the form and give us the information or give us a call about your delivery. We provide you a taxi to deliver your things on time. "
    },
  ]
  
  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} className='shopping container'>
      <Banner comp="home" title="Shopping Taxi Service" description="Need a ride to shop in your city? Call White Cabs at 780 203 8000 for low-rate shopping taxi service in Canada. Book a ride from home to malls or airport for personal, business, or tourist purposes. Sedan, SUV, and Tempo options available, plus wheelchair van service. All taxis are sanitized.
" bannerImg={bannerImg} />
      <Benefits title="Why should you use White Cabs?" points={points}  />
      <Faq title="Frequently Asked Questions" data={faq} />
    </motion.div>
  )
}

export default ShoppingTaxi