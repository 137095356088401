import React from 'react'
import BigCard from '../BigCard/BigCard'
import './services.css'

const Services = ({data, title}) => {
  return (
    <div className="our__services">
        <h2>{title}</h2>
        <div className="card__container">
        {data.map((elm,index) => (
          <BigCard key={index}
          title={elm.title}
          desc={elm.desc}
        />
        ))}

        </div>
      </div>
  )
}

export default Services