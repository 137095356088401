import React from 'react'
import './hotelTaxi.css'
import bannerImg from '../../images/hotelTaxi.jpg'
import Benefits from '../../components/Benefits/Benefits'
import Banner from '../../components/Banner/Banner'
import Services from '../../components/Services/Services'
import { motion } from 'framer-motion';

const points = [
  {
    text: "price of taxi service for hotel is reasonable and cheapest."
  },
  {
    text: "White cabs drivers are punchual."
  },
  {
    text: "Our taxis are neat & clean."
  },
  {
    text: "Sanitized taxi."
  },
  {
    text: "Varities of taxis such as Sedan, SUV and Tempo."
  },
  {
    text: " Provide free luggage service."
  },
]

const bookingService= [
  {
    title: "Book and get to your hotel Taxi Service White cabs",
    desc: "Contact us at 780 203 8000 for a taxi from the airport to your lodge in Canada. We offer hotel taxis for tourists unfamiliar with the area and timely airport taxis. Our accommodating drivers will stop along the way if necessary or take a shortcut to ensure you arrive on time. Book now to enjoy your trip stress-free."
  },
  {
    title: "Book a hotel taxi service from Airport to the Motel",
    desc: "For affordable and high-quality hotels in Canada, contact us. Our hotels offer convenient hotel taxi services for tourists. Book a taxi by calling 780 203 8000 for stress-free transportation to your dream hotel. Our professional drivers will assist with your luggage and provide reliable and hassle-free service. We also offer taxi services for Sherwood Park, Parkland, and business needs. Book a cab now to explore different places with your family."
  },
]

const HotelTaxi = () => {
  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} className='home container'>
      <Banner comp="home" title="Hotel Taxi Service" description="Planning a trip to Canada? Canada's top cab service is just a phone call away at 780 203 8000. We offer lodging and taxi services for visitors staying for a few days. Our hotel taxi service will pick you up at the station and take you to your destination. White cabs provide affordable taxis for shopping, office, personal, and tourist use. We offer a variety of vehicle options, including sedans, SUVs, and tempos, all sanitized for your safety.
" bannerImg={bannerImg} />
      <Services title="Our Services" data={bookingService} />
      <Benefits title="Why choose White Cabs"  points={points}  />
    </motion.div>
  )
}

export default HotelTaxi