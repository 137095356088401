import React from 'react'
import Banner from '../../components/Banner/Banner'
import Benefits from '../../components/Benefits/Benefits'
import Faq from '../../components/Faq/Faq'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/car.svg'
import { motion } from 'framer-motion';

const wheelchairTaxi = () => {
  const points = [
    {
      text: "Travel for vaccination"
    },
    {
      text: "Treatment"
    },
    {
      text: "programs for the day."
    },
    {
      text: "holidays with family and friends."
    },
    {
      text: "airport transfer."
    },
    {
      text: "weekend special."
    },
  ]

  const points1 = [
    {
      text: "White cabs Drivers & Passengers wear masks."
    },
    {
      text: "All taxis are sanitized."
    },
    {
      text: "No extra charges collect from passengers. "
    },
    {
      text: "Cheap & flat-rate WheelChair ."
    },
    {
      text: "Available taxis are SUV, Sedan, and Tempo."
    },
    {
      text: "Make luggage services available for free."
    },
  ]

  const wheelchairService= [
    {
      title: "Safe and Secure journey",
      desc: "White Cabs has a team of well-trained drivers committed to providing safe, elegant, and reliable taxi transportation services. Our fleet of clean and comfortable cabs cater to all citizens, including those with special needs. Our drivers are familiar with the Spruce Grove area, medical facilities, and popular destinations, providing safe transport for medical appointments, shopping, or dining out. White Cabs also provides wheelchair taxi service for injured patients."
    },
    {
      title: "Professional and well-trained drivers",
      desc: "Our experienced drivers and customer service reps are available to meet your transportation needs. With over 5 years of experience in wheelchair transportation, our team is well-trained and professional. They wear masks and proper uniforms with legal documents for a safe and comfortable ride."
    },
    {
      title: "All-time available WheelChair taxi",
      desc: "White Cabs offers flat-rate taxi services for any purpose in Canada, with 24/7 availability. Wheelchair taxi service is also available around the clock to cater to our passengers' needs. We prioritize our passengers' expectations and offer exceptional service to tourists and customers. In case of emergencies, we are always at your service. Our service is comfortable and enjoyable for our handicapped customers."
    },
  ]
  
  const faq = [
    {
      title: "Can I hire a taxi for handicaps in Canada?",
      desc: "Yes, White cabs are provided handicap cabs in Canada. White cabs are available in these areas Spruce Grove, Stony Plain, Edmonton, ST. Albert, Parkland, Morinville."
    },
    {
      title: "Is 24 hours taxi available for handicapped in Canada?",
      desc: "Yes, you can book a taxi any time from anywhere in Canada. Our drivers give the best service to you in their wheelchair van. "
    },
    {
      title: " The sanitized cab is available for the handicapped? ",
      desc: " Yes, White cabs provide sanitized Wheel Chair Van for the handicapped day & night. Our drivers wash and sanitized their cabs every day. Also, you can book taxis according to your need like Sedan, SUV, Tempo."
    },
    {
      title: " Can I hire a taxi at a flat rate in Canada? ",
      desc: " Yes, you can hire a taxi at a cheap rate for different towns. You can also book our taxi at night. Our drivers charge only genuine prices. "
    },
  ]

  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} className='wheelchair container'>
      <Banner comp="home" title="WheelChair Taxi" description="White Cabs provides accessible transportation for those with wheelchairs, scooters, and other mobility aids. Our medical transportation service includes door-to-door service. Reliable and comfortable wheelchair taxi service is available 7 days a week, with ramps and special bumpers for rainy days. Get to your destination within the city or nearby areas with White Cabs.
" bannerImg={bannerImg} />
      <Benefits title="White cabs provide WheelChair taxi service" points={points}  />
      <Services title="Our on demand Services" data={wheelchairService} />
      <Benefits title="Why do passengers choose White cabs " points={points1}  />
      <Faq title="Frequently Asked Questions" data={faq} />
    </motion.div>
  )
}

export default wheelchairTaxi